import { createTheme, ThemeOptions } from "@mui/material/styles";

const defaultTheme = createTheme();
const { breakpoints } = defaultTheme;

const grey300 = "#e0e0e0";
const green = "#04af65";
const greenLight = "#e5edea";
const purple = "#8671ff";
const purpleLight = "#eae8f7";
const pink = "#cb269f";
const yellow = "#FFB95B";
const red = "#cc0000";
const white = "#fff";

const options: ThemeOptions = {
  layout: {
    headerHeight: {
      mobile: "50px",
      desktop: "50px",
    },
    containerPaddingHorizontal: defaultTheme.spacing(2),
  },
  border: {
    light: "1px solid #F0F0F0",
    default: "1px solid #E5E5E7",
  },
  palette: {
    mode: "dark",
    primary: {
      light: greenLight,
      main: green,
      contrastText: white,
    },
    secondary: {
      light: purpleLight,
      main: purple,
    },
    pink: {
      main: pink,
    },
    error: {
      main: red,
    },
    text: {
      // primary: paragraphColor,
      // secondary: headingColor,
    },
    grey: {
      // 300: grey300,
      // 400: grey400,
      // 600: grey600,
    },
    interaction: {
      test: { main: purple },
      // event: { main: paragraphColor },
      treatment: { main: pink },
      document: { main: grey300 },
    },
    eventType: {
      NEWP: {
        light: "#FFE2E6",
        main: pink,
      },
      TREA: {
        light: "#bfd9f1",
        main: purple,
      },
      FOLL: {
        light: "#c7ffee",
        main: green,
      },
      OTHE: {
        light: "#FFF4E5",
        main: yellow,
      },
    },
  },
  typography: {
    bodySmall: {
      fontSize: "14px",
      [breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    body1: {
      fontSize: "16px",
    },
    body2: {
      fontSize: "14px",
    },
    h1: {
      fontSize: "32px",
      [breakpoints.down("sm")]: {
        fontSize: "28px",
      },
      fontWeight: "bold",
      // color: headingColor,
    },
    h2: {
      fontSize: "27px",
      [breakpoints.down("sm")]: {
        // fontSize: "23px",
      },
      fontWeight: "bold",
      // color: headingColor,
    },
    h3: {
      fontSize: "23px",
      [breakpoints.down("sm")]: {
        fontSize: "20px",
      },
      fontWeight: "bold",
      // color: headingColor,
    },
    h4: {
      fontSize: "18px",
      fontWeight: "bold",
      // color: headingColor,
    },
    button: {
      textTransform: "none",
    },
    a: {
      textDecoration: "none",
    },
  },
  overrides: {
    MuiListSubheader: {
      root: {
        backgroundColor: green,
        color: "red",
      },
    },
    MuiTab: {
      labelIcon: {
        "& .MuiTab-wrapper > *:first-child": {
          marginBottom: 0,
        },
      },
    },
    MuiTableHead: {
      root: {
        background: purpleLight,
      },
    },
    MUIRichTextEditor: {
      root: {},
      container: {
        border: "1px solid #c4c4c4",
        borderRadius: 13,
      },
      toolbar: {
        borderBottom: "1px solid #c4c4c4",
      },
      editor: {
        padding: "20px",
        paddingTop: 0,
        height: "323px",
        maxHeight: "323px",
        overflow: "auto",
      },
      placeHolder: {
        paddingLeft: 20,
      },
      anchorLink: {
        color: green,
        textDecoration: "none",
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          color: green,
          textDecoration: "none",
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: "bold",
      },
      secondary: {
        fontSize: "16px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "inherit",
        marginRight: "16px",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        // color: headingColor,
        // backgroundColor: purpleLight,
        padding: "10px",
        fontWeight: "normal",
      },
      arrow: {
        color: purpleLight,
        fontSize: ".66em",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        // background: purpleLight,
        // color: headingColor,
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: 23,
      },
    },
    MuiStepper: {
      root: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
  },
};

export default createTheme(options);
