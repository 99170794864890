import React, { useEffect } from "react";
import Menu from "./components/Menu";
import Footer from "components/Footer";
import Content from "./components/Content";
import { Grid } from "@mui/material";
import useHeight from "theme/layout";
import TagManager from "react-gtm-module";

function WatchView({ video }) {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: `/${video.language}/${video.code}`,
        pageTitle: `Video: ${video.title}`,
      },
    });
  }, [video.language, video.code, video.title]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const height = useHeight;

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="stretch"
    >
      <Grid item>
        <Menu video={video} />
      </Grid>
      <Grid
        item
        sx={{
          backgroundColor: "black",
          height: {
            xs: "50vh",
            sm: "100vh",
            md: `calc(100vh - ${height.menu}px - ${height.footer}px)`,
          },
        }}
      >
        <Content video={video} />
      </Grid>
      <Grid item>
        <Footer />
      </Grid>
    </Grid>
  );
}

export default WatchView;
