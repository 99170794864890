const Gate = {
  code: "demo-webinar",
  defaultCallToActionUrl: "https://legit.health/?lang=es",
  hubspotForm: {
    portalId: "6128500",
    formId: "274e039a-2d6f-4d78-b1e5-0d66f426a4f2",
  },
  defaultText: {
    overline: "Contenido Exclusivo",
    title: "Accede al contenido",
    subtitle: "Rellena el formulario para acceder al contenido",
    enticement: "En menos de 2 segundos",
    privacyNotice:
      "Legit.Health necesita esta información de contacto para darte acceso al contenido. Puedes ejercer tus derechos de desestimiento en cualquier momento. Más información sobre prácticas de privacidad y nuestro compromiso de proteger tu privacidad en nuestra <a href='https://app.legit.health/p/legal' target='_blank'>Política de privacidad</a>.",
    companyTagline: "Inteligencia Clínica para Dermatólogos de Vanguardia.",
    chatBubbleHelp: "¿Tienes dudas?",
  },
};

export default Gate;
