import format from "date-fns/format";

export const DATE_FORMAT = "dd/MM/yyyy";

export function formatDate(
  date: Date | string | number,
  options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  },
  locale = "es-ES"
): string {
  const dateToFormat = date instanceof Date ? date : new Date(date);
  return new Intl.DateTimeFormat(locale, options).format(dateToFormat);
}

export const buildFormatDate =
  (locale: string) =>
  (
    date: Date | string | number,
    options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    }
  ): string => {
    const dateToFormat = date instanceof Date ? date : new Date(date);
    return new Intl.DateTimeFormat(locale, options).format(dateToFormat);
  };

export function formatTime(date: Date): string {
  const dateToFormat = date instanceof Date ? date : new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(dateToFormat);
}

export function formatDateTime(date: Date | string, locale = "es-ES"): string {
  const dateToFormat = date instanceof Date ? date : new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  };
  return new Intl.DateTimeFormat(locale, options).format(dateToFormat);
}

export const buildFormatDateTime =
  (locale: string) =>
  (date: Date | string): string => {
    const dateToFormat = date instanceof Date ? date : new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      // year: "numeric",
      // month: "short",
      // day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };
    return new Intl.DateTimeFormat(locale, options).format(dateToFormat);
  };

export function formatDateToBackendFormat(date: Date): string {
  return format(date, DATE_FORMAT);
}

export function cloneDate(date: Date): Date {
  return new Date(date.getTime());
}
