import React, { ReactElement } from "react";
import ContentView from "./ContentView";

function Content({ headerAndFooterHeight, video }): ReactElement {
  return (
    <ContentView headerAndFooterHeight={headerAndFooterHeight} video={video} />
  );
}

export default Content;
