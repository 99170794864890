import React from "react";
import { AppBar, Toolbar, Typography, Box, Button } from "@mui/material";
import LegitHealthIcon from "images/LegitHealthIcon";
import useHeight from "theme/layout";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/material/styles";
import Strings from "config/gate.js";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  position: "absolute",
  bottom: -2,
  right: 11,
  [theme.breakpoints.up("sm")]: {
    bottom: 6,
    right: 16,
  },
  animation: "ripple 2s infinite ease-in-out",
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "80%": {
      opacity: 0,
    },
    "100%": {
      transform: "scale(2)",
      opacity: 0,
    },
  },
}));

function FooterView() {
  const height = useHeight;
  const strings = Strings;
  return (
    <AppBar
      sx={{
        top: "auto",
        bottom: 0,
        height: `${height.footer}px`,
        justifyContent: "center",
        position: { xs: "fixed", sm: "sticky", md: "fixed" },
      }}
      component="div"
    >
      <Toolbar>
        <Box>
          <Box display="flex" alignItems="center">
            <LegitHealthIcon color="secondary" sx={{ mr: 1 }} />
            <Typography
              sx={{
                fontFamily: "Roboto Condensed",
                letterSpacing: ".23em",
                background: "-webkit-linear-gradient(right, #04af65, #8671ff)",
                backgroundClip: "text",
                textFillColor: "transparent",
                backgroundBlendMode: "multiply",
              }}
              variant="h3"
            >
              LEGIT.HEALTH
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              href="https://app.legit.health/p/legal"
              target="_blank"
              color="inherit"
              sx={{ opacity: 0.8, fontWeight: 400 }}
              startIcon={<HistoryEduIcon />}
              variant="text"
            >
              Legal
            </Button>
            <Typography variant="body2" noWrap sx={{ opacity: 0.9 }}>
              |&nbsp;&nbsp;{strings.defaultText.companyTagline}
            </Typography>
          </Box>
        </Box>

        <Box flexGrow={1}></Box>

        <Box
          mr="60px"
          sx={{
            display: "flex",
            justifyContent: "center",
            visibility: { xs: "hidden", md: "visible" },
          }}
        >
          <Typography>{strings.defaultText.chatBubbleHelp}</Typography>
          <ChevronRightIcon />
        </Box>
        <StyledBox height={60} width={60}></StyledBox>
      </Toolbar>
    </AppBar>
  );
}

export default FooterView;
