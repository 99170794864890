import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import ReactPlayer from "react-player";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { styled } from "@mui/material/styles";
import useHeight from "theme/layout";

const height = useHeight;

const StyledBoxTop = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: `${height.menu}px`,
  left: 0,
  zIndex: 9999999,
  padding: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: "75%",
  },
  [theme.breakpoints.up("xl")]: {
    width: "83.33%",
  },
  animation: `fadeAway 6s`,
  "@keyframes fadeAway": {
    "0%, 80%": {
      backgroundColor: "black",
    },
    "100%": {
      backgroundColor: "transparent",
    },
  },
}));

const StyledBoxBottom = styled(Box)(({ theme }) => ({
  position: "relative",
  bottom: `${height.footer}px`,
  height: `${height.footer}px`,
  left: 0,
  zIndex: 9999,
  animation: `fadeAway 6s`,
}));

const transparentBoxMultiplier = 40;

function Webinar({ video }) {
  const initialVolume = 0;
  const [volume, setVolume] = useState(initialVolume);
  const height = useHeight;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "black",
          height: {
            xs: "50vh",
            sm: "100vh",
            md: `calc(100vh - ${height.menu}px - ${height.footer}px)`,
          },
        }}
      >
        <ReactPlayer
          url={`https://youtu.be/${video.youtubeId}?t=${video.startAt}`}
          volume={volume}
          muted={volume === 0}
          playing
          height="100%"
          width="100%"
          controls={false}
          modestbranding="0"
          rel="0"
          cc_load_policy="1"
          loop
        />
        <Box
          sx={{
            height: {
              xs: "50vh",
              sm: "100vh",
              md: `calc(100% - ${height.menu}px - ${height.footer}px)`,
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "75%",
              xl: "83.33%",
            },
            backgroundColor: volume === 0 ? "#00000080" : "transparent",
            position: "absolute",
            top: `${height.menu}px`,
            left: 0,
            zIndex: 999999,
          }}
        >
          {volume === 0 && (
            <Button
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999999,
                boxShadow: 10,
                // height: 60,
                fontSize: 20,
              }}
              startIcon={<VolumeUpIcon size="large" />}
              variant="contained"
              disableElevation
              size="large"
              onClick={() => setVolume((volume) => 1)}
            >
              {video.language === "es" ? "Activar sonido" : "Turn sound on"}
            </Button>
          )}
        </Box>
        <StyledBoxTop>
          <Button
            variant="contained"
            color="secondary"
            disabled={volume === 0}
            sx={{ opacity: volume !== 0 ? 1 : 0 }}
            disableElevation
            size="large"
            onClick={() => setVolume((volume) => 0)}
            startIcon={<VolumeOffIcon />}
          >
            Mute
          </Button>
        </StyledBoxTop>
        <StyledBoxBottom />
      </Box>
      {/* This code adds a lot of transparent boxes to annoy people who remove them with inspect element */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        {[...Array(transparentBoxMultiplier)].map((e, i) => (
          <Box
            key={Math.random()}
            height="100%"
            width="100%"
            sx={{
              backgroundColor: "transparent",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          ></Box>
        ))}
      </Box>
    </>
  );
}

export default Webinar;
