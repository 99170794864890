import React from "react";
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  AvatarGroup,
  Avatar,
  Badge,
} from "@mui/material";
import Webinar from "./components/Webinar";
import BelowChatCalendar from "components/BelowChatCalendar";
import { styled } from "@mui/material/styles";
import useHeight from "theme/layout";
import image0 from "./peopleImages/0.jpg";
import image1 from "./peopleImages/1.jpg";
import image2 from "./peopleImages/2.jpg";
import image3 from "./peopleImages/3.jpg";
import useFormatDateTime from "hooks/useFormatDateTime";

let now = Date.now();

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiAvatar-root": {
    border: "none",
  },
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    boxShadow: `0 0 0 1px white`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 2s infinite ease-in-out",
      border: "1px solid white",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(3)",
      opacity: 0,
    },
  },
}));

const people = [
  {
    image: image0,
  },
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image: image3,
  },
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];

function ContentView({ video }) {
  const height = useHeight;
  const formatDateTime = useFormatDateTime();
  return (
    <Grid
      item
      container
      sx={{
        marginTop: { xs: `${height.menu}px`, sm: 0, md: `${height.menu}px` },
        paddingBottom: {
          xs: `${height.footer}px`,
          sm: `${height.footer}px`,
          md: `${height.footer}px`,
        },
        height: {
          xs: "50vh",
          sm: "100vh",
          md: `calc(100vh - ${height.menu}px - ${height.footer}px)`,
        },
      }}
    >
      <Grid item xs={12} sm={12} md={9} xl={10}>
        <Webinar video={video} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        xl={2}
        sx={{ display: { xs: "none", sm: "none", md: "inherit" } }}
      >
        <Paper
          sx={{
            zIndex: 999,
            position: "absolute",
          }}
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{
              height: `calc(100vh - ${height.menu}px - ${height.footer}px)`,
            }}
          >
            <Grid item>
              <List disablePadding>
                <ListItem sx={{ backgroundColor: "primary.main" }}>
                  <ListItemText
                    primary={
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                      >
                        Chat
                      </Typography>
                    }
                  />
                  <AvatarGroup max={4} total={24}>
                    {people.map((person) => (
                      <StyledBadge
                        key={Math.random()}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          alt="Connected"
                          src={person.image}
                          variant="circular"
                        />
                      </StyledBadge>
                    ))}
                  </AvatarGroup>
                </ListItem>
                <Box
                  display="block"
                  sx={{
                    height: `calc(100vh - ${height.menu}px - ${height.footer}px - ${height.chatHeader}px - ${height.disabledSendMessageButtonBox}px - ${height.enabledSendMessageButtonBox}px)`,
                    width: "100%",
                    overflow: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  <ListItem
                    sx={{
                      opacity: 0.7,
                      backgroundColor: "#00000050",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#ffffff10",
                        marginY: 1,
                        padding: 1,
                        border: "1px solid white",
                        opacity: 0.7,
                        borderRadius: 2,
                        marginX: "auto",
                      }}
                    >
                      <Typography variant="body2" align="center">
                        {video.language === "es"
                          ? "Sólo los asistentes pueden ver los mensajes."
                          : "Only logged in members can see the messages."}
                      </Typography>
                    </Box>
                  </ListItem>
                  {video.chat.map((message) => (
                    <ListItem key={message}>
                      <ListItemText
                        disableTypography
                        align={
                          message.type === "person"
                            ? "left"
                            : message.type === "system"
                            ? "center"
                            : "right"
                        }
                        primary={
                          message.type !== "system" && (
                            <Typography
                              variant="body2"
                              sx={{ fontSize: ".8em", color: "#ccc" }}
                              gutterBottom
                            >
                              {message.fullName} ·{" "}
                              {formatDateTime(now - 2300000 + message.t)}
                            </Typography>
                          )
                        }
                        secondary={
                          <Typography
                            variant={message.type === "system" && "body2"}
                            sx={
                              message.type !== "system"
                                ? {
                                    lineHeight: 0,
                                    "& a": {
                                      color: "#04af65",
                                    },
                                  }
                                : {
                                    opacity: 0.7,
                                    border: "solid 1px white",
                                    borderRadius: 1,
                                    fontSize: 13,
                                    mx: 1,
                                    p: 0.4,
                                    my: -1,
                                  }
                            }
                            dangerouslySetInnerHTML={{
                              __html: message.content,
                            }}
                          />
                        }
                      ></ListItemText>
                    </ListItem>
                  ))}
                </Box>
              </List>
            </Grid>
            <Grid>
              <BelowChatCalendar video={video} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ContentView;
