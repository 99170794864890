import acneVideo from "images/acneVideo.gif";
import moment from "moment";

let now = Date.now();

const startDatetime = new Date(now + 604800000);
const endDatetime = new Date(now + 604800000 + 2400000);
const duration = startDatetime.getHours() - endDatetime.getHours();

const Featured = {
  code: "ASCORAD",
  youtubeId: "wSVQfDM0Ypw",
  callToAction: {
    href: "https://legit.health/ascorad-el-sistema-de-gradacion-automatico-de-dermatitis-atopica-de-ultima-generacion/?lang=es",
  },
  startAt: 90,
  language: "es-es",
  title: "Presentación del ASCORAD",
  subtitle: "El sistema automático para la gravedad de la dermatitis atópica",
  description:
    "Usando algoritmos de computer vision, los investigadores han creado una herramienta que procesa fotos sacadas con el smartphone y las traduce automáticamente al dominio del SCORAD.",
  airDate: startDatetime,
  avatar: acneVideo,
  chat: false,
  event: null,
  _event: {
    title: "Presentación del ALEGI",
    description:
      "https://legit.health/aladin-medir-la-severidad-del-acne/?lang=es.",
    location: "Online webinar",
    invitees: "cristiansotos@legit.health",
    duration: duration,
    endDatetime: moment(endDatetime).format("YYYYMMDDTHHmmssZ"),
    startDatetime: moment(startDatetime).format("YYYYMMDDTHHmmssZ"),
  },
};

export default Featured;
