import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Grid,
  Container,
  MenuList,
  MenuItem,
  Paper,
  ListItemIcon,
} from "@mui/material";
import { Link } from "react-router-dom";
import Videos from "config/videos.js";
import Featured from "config/featured.js";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import useFormatDate from "hooks/useFormatDate";
import useHeight from "theme/layout";
import Footer from "components/Footer";
import TodayIcon from "@mui/icons-material/Today";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ical from "images/ical.svg";
import googleCalendar from "images/googleCalendar.svg";
import outlook from "images/outlook.svg";
import Image from "@jy95/material-ui-image";
import webinarWithDoctors from "images/webinarWithDoctors.svg";
import utmGenerator from "utils/utmGenerator";
import TagManager from "react-gtm-module";

function ListOfVideosView() {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: "watch/tableOfContents",
        pageTitle: "List of available videos",
      },
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatDate = useFormatDate();
  const videos = Videos;
  const featured = Featured;
  const height = useHeight;

  let now = Date.now();
  const BASEDELAY = 232323232;

  const ATCDropdown = (args) => (
    <Paper>
      <MenuList>
        {args.children.map(
          (link, i) =>
            i < 3 && (
              <MenuItem key={i} sx={{ textDecoration: "none" }}>
                <ListItemIcon>
                  <img
                    src={i === 0 ? googleCalendar : i === 1 ? ical : outlook}
                    alt="ical"
                    width={20}
                  />
                </ListItemIcon>
                <Typography
                  sx={{ a: { color: "white", textDecoration: "none" } }}
                >
                  {link}
                </Typography>
              </MenuItem>
            )
        )}
      </MenuList>
    </Paper>
  );

  const ATCButton = (args) => (
    <Button
      endIcon={<KeyboardArrowDownIcon />}
      startIcon={<TodayIcon />}
      variant="contained"
      onClick={args.onClick}
      href="/"
      size="large"
    >
      {args.children}
    </Button>
  );
  const AddToCalendarDropdown = AddToCalendarHOC(ATCButton, ATCDropdown);

  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: `${height.menu / 1.5}px`,
            paddingBottom: `${height.footer * 1.5}px`,
            marginBottom: 1,
          }}
        >
          <Grid
            item
            xs={12}
            md={5}
            sx={{ display: { xs: "none", md: "initial" } }}
          >
            <Image
              alt="legithealth-webinar"
              src={webinarWithDoctors}
              color="transparent"
              aspectRatio={1.85}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography
              variant="overline"
              sx={{ lineHeight: 1, fontWeight: "bold" }}
              color="primary"
            >
              Próximo webinar
            </Typography>
            <Typography variant="h1">{featured.title}</Typography>
            <Typography variant="h3">{featured.subtitle}</Typography>
            <Typography paragraph color="primary.main">
              {formatDate(featured.airDate)}
            </Typography>
            <Typography paragraph>
              {featured.description.substring(0, 200)}...
            </Typography>
            <Box display="flex" alignItems="flex-start">
              {featured.event && (
                <Box mr={2}>
                  <AddToCalendarDropdown
                    buttonText="Añadir al calendario"
                    event={featured.event}
                  />
                </Box>
              )}
              <Button
                href={utmGenerator(featured.callToAction.href)}
                size="large"
                variant="outlined"
                target="_blank"
              >
                Más información
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="overline"
              sx={{ lineHeight: 1, fontWeight: "bold" }}
              color="secondary"
              margin="auto"
              display="block"
              mt={5}
            >
              Webinar anteriores
            </Typography>
            <Typography align="center" variant="h1">
              Galería de contenido
            </Typography>
            <Typography align="center" paragraph>
              Visualiza la grabación de los contenidos más recientes
            </Typography>
          </Grid>
          {videos.map(
            (video, i) =>
              video.listed && (
                <Grid key={video.youtubeId} item xs={12} md={6}>
                  <Card
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      textDecoration: "none",
                      flexDirection: { xs: "column-reverse", md: "row" },
                    }}
                  >
                    <Box>
                      <CardContent>
                        <Typography component="div" variant="h4" gutterBottom>
                          {video.language.includes("es") ? "🇪🇸" : "🇬🇧"}
                          {` `}
                          {video.title}
                        </Typography>
                        <Typography
                          gutterBottom
                          component="div"
                          variant="body2"
                        >
                          {video.subtitle.substring(0, 70)}...
                        </Typography>
                        <Typography
                          color="text.secondary"
                          component="div"
                          noWrap
                        >
                          {formatDate(now - BASEDELAY * (i + 1) * 1.23)}
                          {` `}
                          {video.language.includes("es")
                            ? "(🇪🇸 Español)"
                            : "(🇬🇧 English)"}
                        </Typography>
                      </CardContent>
                      <Box sx={{ px: 1, pb: 1 }}>
                        <Button
                          aria-label="play/pause"
                          fullWidth
                          sx={{ px: 1, pb: 1 }}
                          variant="outlined"
                          component={Link}
                          color="secondary"
                          to={`/watch/${video.language}/${video.code}`}
                          startIcon={
                            <PlayCircleFilledWhiteIcon
                              sx={{ height: 32, width: 32 }}
                            />
                          }
                        >
                          <Typography variant="h4">
                            {video.language.includes("es")
                              ? "Ver vídeo"
                              : "Watch video"}
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                    <CardMedia
                      component="img"
                      sx={{
                        width: { xs: "100%", md: 200 },
                        maxHeight: { xs: 150, md: "unset" },
                        objectPosition: video.avatarPosition
                          ? video.avatarPosition
                          : "center",
                      }}
                      image={video.avatar}
                      alt={video.title}
                    />
                  </Card>
                </Grid>
              )
          )}
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default ListOfVideosView;
