import React from "react";
import Gate from "components/Gate";
import ListOfVideos from "components/ListOfVideos";
import Watch from "components/Watch";
import theme from "theme/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Videos from "config/videos.js";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PLFK35M",
};
TagManager.initialize(tagManagerArgs);

function App() {
  const videos = Videos;

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Outlet />
        <Routes>
          <Route index path="welcome" element={<Gate />} />
          <Route path="watch">
            <Route index path="tableOfContents" element={<ListOfVideos />} />
            {videos.map((video) => (
              <Route
                key={video.code}
                path={`${video.language}/${video.code}`}
                element={<Watch video={video} />}
              />
            ))}
          </Route>
          <Route path="/*" element={<Navigate replace to="/welcome" />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
