import CjvX6vfieoI from "images/CjvX6vfieoI.png";
import qOiOn6pFGY from "images/qOiOn6pFGY.png";
import wSVQfDM0Ypw from "images/wSVQfDM0Ypw.webp";
import vnqJJpeVOi8 from "images/vnqJJpeVOi8.jpg";
import fWdNgDMK8_0 from "images/fWdNgDMK8_0.jpg";
import oLumG9zpY2c from "images/oLumG9zpY2c.jpg";
import ObOTx1tyVs from "images/ObOTx1tyVs.jpg";
import M88p47CRIo from "images/M88p47CRIo.jpg";
import taigAndElena from "images/taigAndElena.jpg";
import h9awNudjZZM from "images/h9awNudjZZM.jpg";
import hrusF8aEHQ from "images/hrusF8aEHQ.jpg";
import k9J3ut4uWkk from "images/k9J3ut4uWkk.jpg";
import shVRZ8bg2Dk from "images/shVRZ8bg2Dk.jpg";
import FVylOhaVgvQ from "images/FVylOhaVgvQ.jpg";
import etbtZweN40 from "images/e-tbtZweN40.jpg";
import C2rTyASeaYg from "images/C2rTyASeaYg.jpg";
import _rVWExFaWrI from "images/_rVWExFaWrI.webp";
import OZn5hFHcIJc from "images/OZn5hFHcIJc.jpg";
import xIMG_AASEVk from "images/xIMG_AASEVk.jpg";
import sBvi1bMnrmY from "images/sBvi1bMnrmY.jpg";
import H0STOOMP1Dk from "images/H0STOOMP1Dk.jpg";
import eMjisRedBU from "images/eMjisRedBU.webp";
import TXtcJcSx8B4 from "images/TXtcJcSx8B4.webp";
import n_HnjzXFYok from "images/n_HnjzXFYok.webp";
import YPfU5uPTIvc from "images/YPfU5uPTIvc.webp";
import XAf1l46fT0 from "images/XAf1l46fT0.webp";

const Videos = [
  {
    code: "estudio-melanoma-con-inteligencia-artificial-cruces-basurto",
    youtubeId: "XAf1l46f-T0",
    listed: true,
    startAt: 1,
    language: "es",
    title: "Estudio de validación de I.A. para la detección de melanoma",
    subtitle:
      "Taig Mac Carthy presenta en el Congreso de la AEDV los resultados del estudio de validación de una IA para la detección de melanoma",
    avatar: XAf1l46fT0,
    avatarPosition: "right",
    chat: [
      {
        fullName: "Sara Recalde",
        t: 0,
        content:
          'Puedes encontrar más información sobre Taig Mac Carthy en su <a target="_blank" href="https://www.linkedin.com/in/taigmaccarthy/">perfil de LinkedIn</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 100000,
        content:
          'También puedes descubrir más sobre la escala ALADIN en <a href="https://legit.health/?lang=es" target="_blank">su página web</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 400000,
        content:
          'Puedes contactar con Taig Mac Carthy en <a target="_blank" href="mailto:sales@legit.health?subject=Más información sobre la escala ALADIN&body=He visto el vídeo de la AEDV sobre el ALADIN y quiero más información.">sales@legit.health</a>.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "El administrador cambió los permisos. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "escala-gravedad-del-acne-AEDV-2023",
    youtubeId: "YPfU5uPTIvc",
    listed: true,
    startAt: 1,
    language: "es",
    title: "Escala ALADIN para medición automática de la gravedad de acne",
    subtitle:
      "Alfonso Medela explica la nueva escala ALADIN para medición automática de la gravedad de acne en el Congreso de la AEDV de 2023",
    avatar: YPfU5uPTIvc,
    avatarPosition: "right",
    chat: [
      {
        fullName: "Sara Recalde",
        t: 0,
        content:
          'Puedes encontrar más información sobre Alfonso Medela en su <a target="_blank" href="https://www.linkedin.com/in/alfonsomedela/">perfil de LinkedIn</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 100000,
        content:
          'También puedes descubrir más sobre la escala ALADIN en <a href="https://legit.health/aladin-medir-la-severidad-del-acne/?lang=es" target="_blank">su página web</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 400000,
        content:
          'Puedes contactar con Alfonso Medela en <a target="_blank" href="mailto:sales@legit.health?subject=Más información sobre la escala ALADIN&body=He visto el vídeo de la AEDV sobre el ALADIN y quiero más información.">sales@legit.health</a>.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "El administrador cambió los permisos. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "digitalising-clinical-trials-almirall-and-legithealth",
    youtubeId: "TXtcJcSx8B4",
    listed: true,
    startAt: 1,
    language: "en",
    title: "Digitalising clinical trials with AI (Almirall + Legit.Health)",
    subtitle:
      "Diego Herrera (Almirall) and Taig Mac Carthy (Legit.Health) explain how it can be possible to improve clinical trials with digitalisation, and more significantly, artificial intelligence.",
    avatar: TXtcJcSx8B4,
    chat: [
      {
        fullName: "Sara Recalde",
        t: 0,
        content:
          'You can find more information about Diego Herrera in <a target="_blank" href="https://www.linkedin.com/in/diego-herrera-64257016/">his LinkedIn profile</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 0,
        content: `Likewise, here is <a target="_blank" href="https://www.linkedin.com/in/taigmaccarthy">Taig Mac Carthy's LinkedIn profile</a>.`,
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 100000,
        content:
          'You can also find out more about Almirall at the <a href="https://www.almirall.com/our-expertise/r-d-at-almirall/clinical-trials" target="_blank">Almirall website</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 400000,
        content:
          'Feel free to reach out to Mr. Mac Carthy at <a target="_blank" href="mailto:sales@legit.health?subject=¿How does Legit.Health help my company?&body=I have seen your webinar and I want more information about Legit.Health.">sales@legit.health</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 500000,
        content:
          'You can also <a href="http://legit.health/redirect-to-bookings" target="_blank">book a meeting directly with him</a> in the slot that suits you best.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "The administrator changed the visibility settings. Now the chat it private.",
        type: "system",
      },
    ],
  },
  {
    code: "winner-pitch-at-100startups-health-event",
    youtubeId: "n_HnjzXFYok",
    listed: false,
    startAt: 1,
    language: "en",
    title: "Legit.Health's pitch at 100Startups Health",
    subtitle:
      "Watch Taig Mac Carthy's (Legit.Health) pitch at the 100Startups Health event, which won the pitch competition",
    avatar: n_HnjzXFYok,
    chat: [
      {
        fullName: "Sara Recalde",
        t: 400000,
        content:
          'Feel free to reach out to Mr. Mac Carthy at <a target="_blank" href="mailto:sales@legit.health?subject=¿How does Legit.Health help my company?&body=I have seen your webinar and I want more information about Legit.Health.">sales@legit.health</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 500000,
        content:
          'You can also <a href="http://legit.health/redirect-to-bookings" target="_blank">book a meeting directly with him</a> in the slot that suits you best.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "The administrator changed the visibility settings. Now the chat it private.",
        type: "system",
      },
    ],
  },
  {
    code: "alfonso-medela-tedx-medicina-del-futuro",
    youtubeId: "C2rTyASeaYg",
    listed: true,
    startAt: 280,
    language: "es",
    title: "TEDx: La medicina del futuro, por Alfonso Medela de Legit.Health",
    subtitle:
      "Alfonso Medela, Chief Artificial Intelligence Officer en Legit.Health",
    avatar: C2rTyASeaYg,
    chat: [
      {
        fullName: false,
        t: 0,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Automatic-SCORAD-con-Dr-Grimalt",
    youtubeId: "1hrusF8aEHQ",
    listed: true,
    startAt: 0,
    language: "es",
    title: "Dr. Ramón Grimalt y el Automatic SCORAD",
    subtitle:
      "Descubre el SCORAD Automático, la nueva herramienta esencial para dermatitis atópica.",
    avatar: hrusF8aEHQ,
    chat: [
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 0,
        content: "Si alguien tiene dudas, puede utilizar el chat",
        type: "person",
      },
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 60000,
        content: "También hay más información en www.legit.health",
        type: "person",
      },
      {
        fullName: false,
        t: 1000000,
        content: "Andy se unió al chat",
        type: "system",
      },
      {
        fullName: false,
        t: 2302300,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "GE-healthcare-and-Legit-Health",
    youtubeId: "H0STOOMP1Dk",
    listed: true,
    startAt: 52,
    language: "en",
    title: "GE Healthcare & Legit.Health",
    subtitle:
      "Alfonso Medela and his team explain how Artificial Intelligence can help care providers with issues around Pressure ulcers and Dermatological diseases.",
    avatar: H0STOOMP1Dk,
    chat: [
      {
        fullName: "Sara Recalde",
        t: 0,
        content:
          'Feel free to reach out to Mr. Sotos at <a target="_blank" href="mailto:sales@legit.health?subject=¿How does Legit.Health help my company?&body=I have seen your webinar and I want more information about Legit.Health.">sales@legit.health</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 100000,
        content:
          'You can also <a href="http://legit.health/redirect-to-bookings" target="_blank">book a meeting directly with him</a> in the slot that suits you best.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "The administrator changed the visibility settings. Now the chat it private.",
        type: "system",
      },
    ],
  },
  {
    code: "ECHAlliance-Anthony-Armenta",
    youtubeId: "e-MjisRedBU",
    listed: false,
    startAt: 1,
    language: "en",
    title: "Interview to Anthony Armenta from ECHAlliance",
    subtitle:
      "Anthony Armenta tells us about his experience in his role as a Communications Officer at ECHAlliance.",
    avatar: eMjisRedBU,
    chat: [
      {
        fullName: "Sara Recalde",
        t: 0,
        content:
          'You can find more information about Anthony Armenta in his <a target="_blank" href="https://www.linkedin.com/in/anthony-armenta-health-comms/">LinkedIn profile</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 100000,
        content:
          'You can also find out more about his organisation at the <a href="https://echalliance.com/" target="_blank">ECHAlliance website</a>.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "The administrator changed the visibility settings. Now the chat it private.",
        type: "system",
      },
    ],
  },
  {
    code: "doble-inteligencia-elena-sanchez-largo",
    youtubeId: "shVRZ8bg2Dk",
    listed: true,
    startAt: 66,
    language: "es",
    title: "La Doble Inteligencia de la Dra. Sanchez-Largo",
    subtitle:
      "Descubre el futuro seguimiento telemático de los pacientes con psoriasis. Comunicación oral en el Congreso de la AEDV 2022",
    avatar: shVRZ8bg2Dk,
    chat: [
      {
        fullName: "Sara Recalde",
        t: 100000,
        content:
          'Encontrarán más información sobre la Dra. Elena Sánchez-Largo <a target="_blank" href="https://www.linkedin.com/in/m-elena-s%C3%A1nchez-largo-uceda-13b61b233/">en su LinkedIn</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 200000,
        content:
          'Pueden contactar con el equipo de Legit.health en <a target="_blank" href="mailto:sales@legit.health?subject=Mas información sobre Legit.Health&body=He visto el webinar sobre Doble Inteligencia y quiero más información">sales@legit.health</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 300000,
        content:
          'Incluso pueden <a href="http://legit.health/redirect-to-bookings" target="_blank">reservar directamente una reunión</a> en el hueco que mejor les encaje.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Artificial-Intelligence-for-Clinical-Trials",
    youtubeId: "xIMG_AASEVk",
    listed: true,
    startAt: 207,
    language: "en",
    title: "How does Legit.Health help pharmaceutical companies?",
    subtitle:
      "One of Legit.Health's digital solutions expert brilliantly explains how Legit.Health's solution helps pharmaceutical companies.",
    avatar: xIMG_AASEVk,
    chat: [
      {
        fullName: "Sara Recalde",
        t: 0,
        content:
          'Feel free to reach out to an expert at <a target="_blank" href="mailto:sales@legit.health?subject=¿How does artificial intelligence help my pharma company in clinical trials?&body=I have seen your webinar and I want more information about Legit.Health.">sales@legit.health</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 100000,
        content:
          'You can also <a href="http://legit.health/redirect-to-bookings" target="_blank">book a meeting directly with him</a> in the slot that suits you best.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "The administrator changed the visibility settings. Now the chat it private.",
        type: "system",
      },
    ],
  },
  {
    code: "garantizar-la-calidad-de-imagen-en-dermatologia",
    youtubeId: "sBvi1bMnrmY",
    listed: true,
    startAt: 172,
    language: "es",
    title: "Garantizar la utilidad de las imágenes con Inteligencia Artificial",
    subtitle:
      "Ignacio Hernández, data scientist y experto en IA en Legit.Health, explica de qué trata la herramienta DIQA (Dermatology Image Quality Assesment) que garantiza la utilidad clínica de las imágenes para ensayos y estudios clínicos.",
    avatar: sBvi1bMnrmY,
    chat: [
      {
        fullName: "Sara Recalde",
        t: 0,
        content:
          'Pueden contactar con Ignacio Hernández en <a target="_blank" href="mailto:ignaciohernandez@legit.health?subject=Mas información sobre el Dermatology Image Quality Assurance&body=He visto el webinar sobre DIQA quiero más información sobre Legit.Health">ignaciohernandez@legit.health</a>.',
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 100000,
        content:
          'Incluso pueden <a href="http://legit.health/redirect-to-bookings" target="_blank">reservar directamente una reunión con él</a> en el hueco que mejor les encaje.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Diego-Herrera-Almirall",
    youtubeId: "OZn5hFHcIJc",
    listed: true,
    startAt: 0,
    language: "es",
    title: "Entrevista a Diego Herrera",
    subtitle:
      "Entrevistamos al líder de Gestión de Datos de Ensayos Clínicos de Almirall para aprender de la experiencia de este gran profesional",
    avatar: OZn5hFHcIJc,
    chat: [
      {
        fullName: false,
        t: 0,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Esther-Escalero-Viamed-Fuensanta",
    youtubeId: "_rVWExFaWrI",
    listed: true,
    startAt: 0,
    language: "es",
    title: "Entrevista a Esther Escalero",
    subtitle:
      "Experiencias y aprendizajes como Directora Asistencial en Viamed Fuensanta",
    avatar: _rVWExFaWrI,
    chat: [
      {
        fullName: false,
        t: 0,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },

  {
    code: "derivacion-optimizada-con-inteligencia-artificial",
    youtubeId: "GI3tZU_G70k",
    listed: true,
    startAt: 0,
    language: "es",
    title: "Derivación optimizada con Inteligencia Artificial",
    subtitle:
      "Comunicación oral en el Congreso de la AEDV 2022 por Taig Mac Carthy",
    avatar: k9J3ut4uWkk,
    chat: [
      {
        fullName: false,
        t: 0,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Luis-Javier-Bonilla-CompuGroup-Medical",
    youtubeId: "e-tbtZweN40",
    listed: true,
    startAt: 0,
    language: "es",
    title: "Key Opinion Leaders: entrevista a Luis Javier Bonilla",
    subtitle: "Director General de CompuGroup Medical España.",
    avatar: etbtZweN40,
    chat: [
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 0,
        content: "Si alguien tiene dudas, puede utilizar el chat",
        type: "person",
      },
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 60000,
        content:
          "También hay más información sobre CompuGroup Medical en <u>cgm.com</u>.",
        type: "person",
      },
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 68000,
        content:
          "Y podéis consultar el LinkedIn de Luis Javier en <u>linkedin.com/in/luis-javier-bonilla-583640</u>.",
        type: "person",
      },
      {
        fullName: false,
        t: 1000000,
        content: "Andy se unió al chat",
        type: "system",
      },
      {
        fullName: false,
        t: 2302300,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "UAS-automatico-AEDV-2022",
    youtubeId: "FVylOhaVgvQ",
    listed: true,
    startAt: 0,
    language: "es",
    title: "Automatic UAS7 con Inteligencia Artificial (AEDV 2022)",
    subtitle:
      "Cálculo automático de actividad de la urticaria utilizando un algoritmo de aprendizaje profundo para el conteo preciso de habones",
    avatar: FVylOhaVgvQ,
    chat: [
      {
        fullName: false,
        t: 0,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Taig-Mac-Carthy-and-Dr-Elena-Sanchez-Largo-at-Barcelona",
    youtubeId: "R_xRthDguSY",
    listed: true,
    startAt: 0,
    language: "en",
    title: "Taig Mac Carthy and Dr. Elena Sanchez-Largo",
    subtitle: "Showcast of the results of the pilot study",
    avatar: taigAndElena,
    chat: [
      {
        fullName: "Sara Recalde",
        t: 0,
        content: "If someone has questions, feel free to use the chat",
        type: "person",
      },
      {
        fullName: "Sara Recalde",
        t: 60000,
        content:
          "You can also find more information about Legit.Health at www.legit.health",
        type: "person",
      },
      {
        fullName: false,
        t: 2302300,
        content:
          "The admin changed the visibility permissions. Now the chat is private.",
        type: "system",
      },
    ],
  },
  {
    code: "Herminia-Rodriguez-Hospitales-San-Roque",
    youtubeId: "h9awNudjZZM",
    listed: true,
    startAt: 0,
    language: "es",
    title: "Key Opinion Leaders: entrevista a Herminia Rodriguez",
    subtitle: "Vicepresidenta Hospitales San Roque.",
    avatar: h9awNudjZZM,
    chat: [
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 0,
        content: "Si alguien tiene dudas, puede utilizar el chat",
        type: "person",
      },
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 60000,
        content:
          "También hay más información sobre los Hospitales San Roque en <u>hospitalessanroque.com</u>.",
        type: "person",
      },
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 68000,
        content:
          "Y podéis consultar el LinkedIn de Herminia en <u>linkedin.com/in/herminia-rodríguez-rosas-849a1258</u>.",
        type: "person",
      },
      {
        fullName: false,
        t: 1000000,
        content: "Andy se unió al chat",
        type: "system",
      },
      {
        fullName: false,
        t: 2302300,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Crista-Raig-Medable",
    youtubeId: "O-bOTx1tyVs",
    listed: false,
    startAt: 0,
    language: "en",
    title: "Key Opinion Leaders: Crista Raig from Medable",
    subtitle: "Medable is a major global decentralized clinical trial platform",
    avatar: ObOTx1tyVs,
    chat: [
      {
        fullName: "Taig Mac Carthy",
        t: 0,
        content: "If someone has questions, feel free to use the chat",
        type: "person",
      },
      {
        fullName: "Taig Mac Carthy",
        t: 60000,
        content:
          "You can also find more information about Medable at <u>www.medable.com</u>",
        type: "person",
      },
      {
        fullName: false,
        t: 1000000,
        content: "Andy joined the chat",
        type: "system",
      },
      {
        fullName: false,
        t: 2302300,
        content:
          "The admin changed the visibility permissions. Now the chat is private.",
        type: "system",
      },
    ],
  },
  {
    code: "Jesus-Redrado-Vitio",
    youtubeId: "M88p47CRI-o",
    listed: false,
    startAt: 0,
    language: "es",
    title: "Key Opinion Leaders: Entrevista a Jesus Redrado",
    subtitle: "CTO y co-fundador de la startup navarra Vitio",
    avatar: M88p47CRIo,
    chat: [
      {
        fullName: "Taig Mac Carthy",
        t: 0,
        content: "Si alguien tiene dudas, puede utilizar el chat",
        type: "person",
      },
      {
        fullName: "Taig Mac Carthy",
        t: 60000,
        content: "También hay más información en www.legit.health",
        type: "person",
      },
      {
        fullName: false,
        t: 1000000,
        content: "Andy se unió al chat",
        type: "system",
      },
      {
        fullName: false,
        t: 2302300,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Imanol-Ucar-Mapfre",
    youtubeId: "oLumG9zpY2c",
    listed: true,
    startAt: 0,
    language: "es",
    title: "Key Opinion Leaders: Entrevista a Imanol Ucar",
    subtitle: "Experto en Partners y Alianzas Digitales en MAPFRE.",
    avatar: oLumG9zpY2c,
    chat: [
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 0,
        content: "Si alguien tiene dudas, puede utilizar el chat",
        type: "person",
      },
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 60000,
        content:
          "Creo que todos conocemos a MAPRE, pero si alguien quiere más información, puede visitar <u>mapfre.es</u>",
        type: "person",
      },
      {
        fullName: false,
        t: 1000000,
        content: "Andy se unió al chat",
        type: "system",
      },
      {
        fullName: "Nerea Ruiz de Gauna",
        t: 70000,
        content:
          "Sobre Imanol, su LinkedIn es: <u>linkedin.com/in/imanol-ucar-zardoya-7b0a9483</u>.",
        type: "person",
      },
      {
        fullName: false,
        t: 2302300,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Serafin-Balaguer-HLA",
    youtubeId: "fWdNgDMK8_0",
    listed: true,
    startAt: 0,
    language: "es",
    title: "Key Opinion Leaders: Entrevista al Dr. Serafín Balaguer",
    subtitle: "Dr. Serafín Balaguer con Grupo HLA en Almería",
    avatar: fWdNgDMK8_0,
    chat: [
      {
        fullName: "Taig Mac Carthy",
        t: 0,
        content: "Si alguien tiene dudas, puede utilizar el chat",
        type: "person",
      },
      {
        fullName: "Taig Mac Carthy",
        t: 60000,
        content: "También hay más información en www.legit.health",
        type: "person",
      },
      {
        fullName: false,
        t: 1000000,
        content: "Andy se unió al chat",
        type: "system",
      },
      {
        fullName: false,
        t: 2302300,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "DIQA",
    youtubeId: "9qOiOn6pFGY",
    startAt: 90,
    language: "es",
    title: "Presentación del DIQA en el XI congreso del GEDEI",
    subtitle: "Alfonso Medela nos explica el Dermatology Image Quality",
    avatar: qOiOn6pFGY,
    chat: [
      {
        fullName: "Taig Mac Carthy",
        t: 0,
        content: "Si alguien tiene dudas, puede utilizar el chat",
        type: "person",
      },
      {
        fullName: "Taig Mac Carthy",
        t: 60000,
        content: "También hay más información en www.legit.health",
        type: "person",
      },
      {
        fullName: false,
        t: 1000000,
        content: "Andy se unió al chat",
        type: "system",
      },
      {
        fullName: false,
        t: 2302300,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Novartis-Biome-Barcelona-Health-Summit",
    youtubeId: "vnqJJpeVOi8",
    listed: true,
    startAt: 4,
    language: "en-gb",
    title: "Legit.Health and Novartis Biome at the Barcelona Health Summit",
    subtitle:
      "Clara Cuervo interviews Taig Mac Carthy about their common project",
    avatar: vnqJJpeVOi8,
    chat: [
      {
        fullName: "Sara Recalde",
        t: 0,
        content: "If anyone has questions, feel free to use the chat bubble",
        type: "person",
      },
      {
        fullName: false,
        t: 2000000,
        content:
          "The admin changed the visibility permissions. Now the chat is private.",
        type: "system",
      },
    ],
  },
  {
    code: "Inteligencia-artificial",
    youtubeId: "D3yfw1KYWXI",
    listed: true,
    startAt: 279,
    language: "es",
    title: "Inteligencia artificial aplicada en dermatología",
    subtitle: "Seguimiento continuo y online de las patologías de la piel.",
    avatar: CjvX6vfieoI,
    chat: [
      {
        fullName: false,
        t: 0,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
      {
        fullName: "Taig Mac Carthy",
        t: 300000,
        content: "Hemos hecho privado el chat público",
        type: "person",
      },
      {
        fullName: "Taig Mac Carthy",
        t: 400000,
        content: "Si alguien tiene dudas, puede utilizar la burbuja de abajo",
        type: "person",
      },
      {
        fullName: "Taig Mac Carthy",
        t: 500000,
        content: "También hay más información en la web www.legit.health",
        type: "person",
      },
    ],
  },
  {
    code: "Informativos-ETB2",
    youtubeId: "wSVQfDM0Ypw",
    listed: true,
    startAt: 1,
    language: "es",
    title:
      "Informativos ETB2 | Seguimiento continuo de las patologías de la piel con inteligencia artificial",
    subtitle:
      "Seguimiento continuo y online de las patologías de la piel, mejorando el control médico y proporcionando mayor tranquilidad al paciente.",
    avatar: wSVQfDM0Ypw,
    chat: [],
  },
];

export default Videos;
