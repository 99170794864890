import React, { ReactElement } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function LegitHealthIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5,9.1L2.5,9.1L2.5,9.1L2.5,9.1L2.5,9.1L2.5,9.1L2.5,9.1C2.7,9.1,2.9,9,3,9C2.9,9.2,2.7,9.3,2.6,9.4
        C2.5,9.5,2.4,9.6,2.3,9.6c0,0-0.1,0.1-0.1,0.1C2.1,9.8,1.9,9.9,2,10.1c0.1,0.2,0.3,0.2,0.5,0.2c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0
        l0,0c0.7-0.1,1.3-0.4,2-0.8c0.2-0.1,0.4-0.3,0.6-0.4L5.7,9l0,0c-0.3,0.4-0.6,0.8-1,1.2c0,0,0,0-0.1,0c-0.1,0.1-0.3,0.2-0.2,0.4
        c0.1,0.2,0.3,0.2,0.5,0.2c0,0,0.1,0,0.1,0c0.4,0,0.9-0.1,1.4-0.4c0.9-0.5,1.5-1.3,2.1-2.2l0,0C8.5,8,8.6,7.8,8.9,7.8
        c0,0,0.1,0,0.1,0c1,0,1.9,0.2,3,0.7c-0.2,0.1-0.4,0.1-0.6,0.1l-0.1,0c-0.6,0-1.5,0-2.3,0.3c-0.7,0.2-1.2,0.5-1.5,1
        c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.1,0.5c0.1,0.2,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
        c1.2-0.2,2.4-0.1,3.6,0c0.3,0,0.6,0,0.9,0.1c2.6,0.2,4.6-0.2,6.4-1.1c0.8-0.4,1.6-0.9,2.3-1.6l0,0c0.1-0.1,0.2-0.2,0.1-0.4
        c-0.1-0.2-0.3-0.1-0.4,0c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1c-1.7,0.8-3.3,0.9-4.8,0.4c-0.8-0.2-1.5-0.6-2.2-0.9
        L13,7.1c-1.2-0.5-2.2-0.8-3.2-1C9.2,6.1,8.9,6.2,8.7,6.7C8.1,8,7.3,8.9,6.5,9.7c-0.3,0.3-0.7,0.6-1.2,0.6c0,0,0,0,0,0
        c0,0,0-0.1,0.1-0.1c1.4-1.4,2.3-3.1,3-4.4C8.7,5,9.2,4.7,10,4.7c4.3,0,8.5,0.8,12.4,2.6c0,0,0.1,0,0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.1
        c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0-0.1,0-0.2C23,7,22.9,6.9,22.7,6.9c0,0-0.1,0-0.1-0.1c-2.1-1-4.3-1.7-6.6-2.1
        c-2.8-0.5-5.1-0.7-7.3-0.5C8,4.3,7.2,4.4,6.6,5C6.1,5.5,5.5,5.9,4.9,6.3C3.5,7.2,2.1,7.9,0.7,8.4l0,0c-0.1,0-0.3,0.1-0.3,0.3
        C0.3,8.9,0.5,9,0.6,9l0,0C1,9.2,1.4,9.2,1.7,9.2C2,9.2,2.3,9.2,2.5,9.1z M6.6,5.7c-1.2,1.6-3.5,3.2-5.3,3C3.5,7.7,5.2,6.8,6.6,5.7z
         M8,5C7.3,7.1,4.6,9.6,2.9,9.8C4.9,8.2,6.6,6.6,8,5z M11.9,9.1c0.2,0,0.4,0,0.6-0.1c0.2,0,0.5,0,0.8,0.1c0.5,0.2,1.1,0.4,1.8,0.7
        c0.1,0,0.3,0.1,0.4,0c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c-0.8-0.2-1.6-0.6-2.3-0.9
        c-0.4-0.2-0.9-0.4-1.3-0.6c-0.7-0.3-1.4-0.4-2.2-0.5c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0c0,0,0-0.1,0-0.1c0-0.3,0.2-0.5,0.4-0.5
        c0,0,0,0,0,0c0.3,0,0.6,0.1,1,0.1c1,0.2,2,0.7,3,1.1c0.2,0.1,0.4,0.2,0.6,0.3c1.8,0.8,3.4,1,4.9,0.6c0,0,0.1,0,0.1,0
        c-1,0.6-2.1,1-3.5,1.2c-0.6,0.1-1.2,0.1-1.8,0.1c-0.9,0-1.9-0.1-2.8-0.1l-0.3,0c-0.4,0-0.8-0.1-1.2-0.1c-0.5,0-1.1,0-1.6,0.2
        c0.3-0.4,0.8-0.6,1.1-0.7c0.6-0.1,1.2-0.2,1.7-0.2c0.2,0,0.5,0,0.7,0C11.7,9.1,11.8,9.1,11.9,9.1z M23.2,14.4L23.2,14.4L23.2,14.4
        L23.2,14.4c-0.4-0.2-0.8-0.2-1.1-0.2c0,0,0,0-0.1,0c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.3,0.1-0.5,0.1c0.1-0.2,0.3-0.3,0.5-0.4
        c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.2,0.2-0.4c-0.1-0.2-0.3-0.2-0.5-0.2h0c0,0-0.1,0-0.1,0
        c-0.1,0-0.2,0-0.3,0l0,0c-0.7,0.1-1.3,0.4-2,0.8c-0.2,0.1-0.4,0.3-0.6,0.4l-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
        c0.3-0.4,0.6-0.8,1-1.2l0.1,0c0.1-0.1,0.3-0.2,0.2-0.4c-0.1-0.2-0.3-0.2-0.5-0.2c0,0-0.1,0-0.1,0c-0.4,0-0.9,0.1-1.4,0.5
        c-0.9,0.5-1.5,1.3-2.1,2.2l0,0c-0.1,0.2-0.2,0.3-0.5,0.3c0,0-0.1,0-0.1,0c-1,0-1.9-0.2-2.9-0.7c0.2-0.1,0.4-0.1,0.6-0.1h0.1
        c0.6,0,1.5,0,2.3-0.3c0.7-0.2,1.2-0.5,1.5-1c0,0,0,0,0-0.1l0,0c0.1-0.1,0.2-0.3,0.1-0.5c-0.1-0.2-0.3-0.1-0.4-0.1
        c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0l0,0c0,0-0.1,0-0.1,0c-1.2,0.2-2.4,0.1-3.6,0c-0.3,0-0.6,0-0.9-0.1c-2.6-0.2-4.6,0.2-6.4,1.1
        C4,14.4,3.2,15,2.4,15.6l0,0c-0.1,0.1-0.2,0.2-0.1,0.4c0.1,0.2,0.3,0.1,0.4,0C2.9,16,3,15.9,3.1,15.9l0.1,0c0.1,0,0.2-0.1,0.2-0.1
        c1.7-0.8,3.3-0.9,4.8-0.4c0.8,0.2,1.5,0.6,2.2,0.9l0.4,0.2c1.2,0.5,2.2,0.8,3.2,1c0.5,0.1,0.8-0.1,1.1-0.5c0.6-1.3,1.3-2.2,2.2-3
        c0.3-0.3,0.7-0.6,1.2-0.6c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c-1.4,1.4-2.3,3.1-3,4.4c-0.4,0.7-0.9,1-1.7,1.1c-0.1,0-0.2,0-0.3,0
        c-4.2,0-8.3-0.9-12.1-2.6c0,0-0.1,0-0.1-0.1C1.1,16,1,15.9,0.9,16c-0.1,0-0.1,0.1-0.2,0.2c0,0.1,0,0.1,0,0.2
        c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.1,0,0.1,0.1c2.1,1,4.3,1.7,6.6,2.1c2,0.4,3.8,0.6,5.4,0.6c0.6,0,1.3,0,1.9-0.1
        c0.7-0.1,1.5-0.1,2.1-0.7c0.5-0.5,1.1-0.9,1.6-1.2l0-0.1l0,0l0.1,0.1c1.5-0.9,2.8-1.6,4.3-2.1l0,0c0.1,0,0.3-0.1,0.3-0.3
        C23.5,14.6,23.3,14.5,23.2,14.4z M22.5,14.8c-2.2,0.9-3.8,1.9-5.3,3c1.1-1.5,3.2-3,4.9-3C22.3,14.7,22.4,14.8,22.5,14.8z M15.8,18.4
        c0.8-2.1,3.4-4.6,5.2-4.8C18.9,15.2,17.2,16.8,15.8,18.4z M11.9,14.4c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.5,0-0.8-0.1
        c-0.5-0.2-1.1-0.4-1.8-0.7l0,0c-0.1,0-0.3-0.1-0.4,0c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0.2,0.1,0.3,0.1l0,0
        c0,0,0,0,0,0c0.8,0.2,1.6,0.6,2.3,0.9l0,0c0.4,0.2,0.9,0.4,1.3,0.5c0.7,0.3,1.4,0.4,2.2,0.5c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0
        c0,0,0,0.1,0,0.1v0c0,0.3-0.2,0.5-0.4,0.5c-0.3,0-0.7-0.1-1-0.1c-1-0.2-2-0.7-3-1.1c-0.2-0.1-0.4-0.2-0.6-0.3
        c-1.2-0.5-2.3-0.8-3.3-0.8c-0.5,0-1.1,0.1-1.6,0.2c0,0-0.1,0-0.1,0c1-0.6,2.1-1,3.5-1.2c0.6-0.1,1.2-0.1,1.8-0.1
        c0.9,0,1.9,0.1,2.8,0.1l0.2,0c1,0.1,1.9,0.1,2.8-0.1c-0.3,0.4-0.8,0.6-1.1,0.7c-0.6,0.1-1.2,0.2-1.7,0.2l0,0c-0.2,0-0.5,0-0.7,0
        C12.1,14.4,12,14.4,11.9,14.4z"
      />
    </SvgIcon>
  );
}
